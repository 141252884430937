import axios from 'axios';
import { authRefresh, baseURL, mediaBaseURL } from './url';

const $authHost = axios.create({
    baseURL: baseURL,
});

export const $mediaApi = axios.create({
    baseURL: mediaBaseURL,
});

$authHost.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

$authHost.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem('refreshToken');
        if (
            refreshToken &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            const jsonData = { refreshToken };

            try {
                const rs = await $authHost.post(authRefresh, jsonData);

                const { accessToken } = rs.data;
                localStorage.setItem('accessToken', accessToken);
                return $authHost(originalRequest);
            } catch (error) {
                $authHost(originalRequest);
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    },
);

export { $authHost };
