import FileResizer from "react-image-file-resizer";
import { $authHost } from "./https";

export const quillModules = {
  toolbar: {
    container: [
      [
        { header: "1" },
        { header: "2" },
        { header: [3, 4, 5, 6] },
        { font: [] },
      ],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
      ["code-block"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
};

export async function imageHandler() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    var file = input.files ? input.files[0] : null;

    const range = this.quill.getSelection();

    if (file) {
      FileResizer.imageFileResizer(
        file,
        720,
        500,
        file.type,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await $authHost.post(
              "/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            // setImage(data);
            this.quill.insertEmbed(range.index, "image", data.Location);
          } catch (error) {
            console.log(error);
          }
        }
      );
    }
  };
}

export const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "code-block",
];
