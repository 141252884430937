export const languages = {
    logout: {
        en: 'Logout',
        ru: 'Выйти',
        uz: 'Chiqish',
    },
    collapse: {
        en: 'Collapse',
        ru: 'Закрыть',
        uz: 'Yopish',
    },
    edit: {
        en: 'Edit',
        ru: 'Изменить',
        uz: "O'zgartirish",
    },
    delete: {
        en: 'Delete',
        ru: 'Удалить',
        uz: "O'chirish",
    },
    'add-menu': {
        en: 'Add menu',
        ru: 'Добавить меню',
        uz: "Menyu qo'shish",
    },
    'add-link': {
        en: 'Add link',
        ru: 'Добавить ссылку',
        uz: "Link qo'shish",
    },
    'delete-confirm': {
        en: 'Are you sure to delete this element?',
        ru: 'Вы действительно хотите удалить этот элемент?',
        uz: "O'chirishga aminmisiz?",
    },
    yes: {
        en: 'Yes',
        ru: 'Да',
        uz: 'Ha',
    },
    no: {
        en: 'No',
        ru: 'Нет',
        uz: "Yo'q",
    },
    update: {
        en: 'Update',
        ru: 'Обновить',
        uz: 'Yangilash',
    },
    add: {
        en: 'Add',
        ru: 'Добавить',
        uz: "Qo'shish",
    },
    'add-news': {
        en: 'Add news',
        ru: 'Добавить новость',
        uz: "Yangilik qo'shish",
    },
    'add-page': {
        en: 'Add page',
        ru: 'Добавить страницу',
        uz: "Sahifa qo'shish",
    },
    'add-banner': {
        en: 'Add banner',
        ru: 'Добавить баннер',
        uz: "Banner qo'shish",
    },
    'add-cards': {
        en: 'Add card',
        ru: 'Добавить карту',
        uz: "Card qo'shish",
    },
    'add-videos': {
        en: 'Add video',
        ru: 'Добавить видео',
        uz: "Video qo'shish",
    },
    'add-test-venue': {
        en: 'Add test venue',
        ru: 'Добавить площадку',
        uz: "Sinov joyi qo'shish",
    },
    'add-partner': {
        en: 'Add partner',
        ru: 'Добавить партнера',
        uz: "Partner qo'shish",
    },
    'add-detail': {
        en: 'Add detail',
        ru: 'Добавить деталь',
        uz: "Detal qo'shish",
    },
    'add-detail-item': {
        en: 'Add detail',
        ru: 'Добавить деталь',
        uz: "Detal qo'shish",
    },
    'have-a-subtitle': {
        en: 'Add subtitle',
        ru: 'Добавить подзаголовок',
        uz: "Kichik sarlavha qo'shish",
    },
};
