import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import { Space, Table } from "antd";
import clsx from "clsx";
import useLanguage from "../../hooks/useLanguage";
import { detailsDelete, detailsItemDelete } from "../../utils/url";
import DeleteModalBtn from "../Atoms/Buttons/DeleteModalBtn";
import EditDrawerBtn from "../Atoms/Buttons/EditDrawerBtn";
import PostDrawerBtn from "../Atoms/Buttons/PostDrawerBtn";
import { useGetTitle } from "../../hooks/useGetTitle";

export default function PagesBottomItem({ item, request, page_id }) {
  const { translateTitle } = useGetTitle();
  const [open, setOpen] = useState(false);

  const translate = useLanguage();

  const columns = [
    {
      title: "Title",
      dataIndex: translateTitle("title"),
      key: "title",
    },
    {
      title: "Subtitle",
      dataIndex: translateTitle("subtitle"),
      key: "subtitle",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn
            type={"details-item"}
            data={record}
            request={request}
            detail_id={item._id}
            detail_type={item.type}
          />
          <DeleteModalBtn
            url={detailsItemDelete}
            id={record._id}
            request={request}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="pages-bottom__item">
      <div className={clsx("pages-bottom__subheader", open && "active")}>
        <p className="pages-bottom__subtitle">
          <span
            className={clsx(
              "pages-bottom__badge",
              item.type === "accordion" && "blue",
              item.type === "grid" && "red",
              item.type === "url" && "green"
            )}
          >
            {item?.type}
          </span>
          <span className="pages-bottom__text">
            {item[translateTitle("title")]}
          </span>
        </p>
        <Space className="crud__actions fixed-crud__actions">
          {item.items.length !== 0 && (
            <IoIosArrowDropdown
              className={clsx("icon", open && "active")}
              onClick={() => setOpen(!open)}
            />
          )}
          <PostDrawerBtn
            type={"details-item"}
            request={request}
            detail_id={item._id}
            detail_type={item.type}
          >
            <AiOutlinePlusCircle className="icon" />
            {translate("add-detail-item")}
          </PostDrawerBtn>
          <EditDrawerBtn
            type={"details"}
            data={item}
            request={request}
            page_id={page_id}
          />
          <DeleteModalBtn url={detailsDelete} id={item._id} request={request} />
        </Space>
      </div>
      <div className={clsx("pages-bottom__subbottom", open && "active")}>
        <Table
          columns={columns}
          dataSource={item?.items.map((item) => item)}
          bordered
          rowKey="id"
          pagination={false}
        />
      </div>
    </div>
  );
}
