import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tabs,
} from "antd";
import React, { useEffect } from "react";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { useGetTitle } from "../../hooks/useGetTitle";
import { regionData } from "../../utils/data";
import { partnersPost, partnersPut } from "../../utils/url";
import ImageUpload from "../ImageUpload";

export default function PartnersForm({ setOpen, data = null, request }) {
  const { translateTitle } = useGetTitle();
  const postRequest = usePostRequest({ url: partnersPost });
  const putRequest = usePutRequest({ url: `${partnersPut}${data?._id}` });

  const [form] = Form.useForm();

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const onFinish = async (values) => {
    const selectRegion = regionData.filter((x) => x.region === values.region);

    const req = {
      name: values.name,
      phone: values.phone,
      logo: values.logo,
      description: values.description,
      description_ru: values.description_ru,
      description_uz: values.description_uz,
      region: values.region,
      region_id: selectRegion[0].region_id,
      region_ru: selectRegion[0].region_ru,
      region_uz: selectRegion[0].region_uz,
    };

    try {
      if (data !== null) {
        await putRequest.request({
          data: req,
          sync: true,
        });
      } else {
        await postRequest.request({
          data: req,
          sync: true,
        });
      }
      await request();
      setOpen(false);
      message.success("Success!");
    } catch (error) {
      message.error(error.message);
    }
  };

  const onFinishFailed = ({ errorFields }) =>
    message.error(errorFields[0].errors[0]);

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  const items = [
    {
      label: "EN",
      key: "en",
      forceRender: true,
      children: (
        <Col span={24}>
          <Form.Item {...formLabel("Description", "description")}>
            <Input.TextArea
              {...formInput("description")}
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Col>
      ),
    },
    {
      label: "RU",
      key: "ru",
      forceRender: true,
      children: (
        <Col span={24}>
          <Form.Item {...formLabel("Description Ru", "description_ru")}>
            <Input.TextArea
              {...formInput("description_ru")}
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Col>
      ),
    },
    {
      label: "UZ",
      key: "uz",
      forceRender: true,
      children: (
        <Col span={24}>
          <Form.Item {...formLabel("Description Uz", "description_uz")}>
            <Input.TextArea
              {...formInput("description_uz")}
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Col>
      ),
    },
  ];

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className={"fixed-form"}
    >
      <Row>
        <Col span={24}>
          <Form.Item {...formLabel("Name", "name")}>
            <Input {...formInput("name")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...formLabel("Phone", "phone")}>
            <Input type="tel" {...formInput("phone")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...formLabel("Region", "region")}>
            <Select size="middle" width="100%">
              <Select.Option value=""></Select.Option>
              {regionData?.map((item) => {
                return (
                  <Select.Option key={item.region_id} value={item.region}>
                    {item[translateTitle("region")]}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Tabs tabPosition="top" items={items} />
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label="Logo"
            name="logo"
            rules={[
              {
                required: true,
                message: "Please upload Logo!",
              },
            ]}
          >
            <ImageUpload name="logo" data={data} form={form} />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginBottom: 10 }}>
          <Button
            htmlType="reset"
            type="default"
            loading={postRequest.loading || putRequest.loading}
            block
          >
            Reset
          </Button>
        </Col>
        <Col span={24}>
          <Button
            htmlType="submit"
            type="primary"
            loading={postRequest.loading || putRequest.loading}
            block
          >
            {data !== null ? "Edit" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
