export const regionData = [
  {
    region_id: "671d30594130dfe30243745d",
    region: "Tashkent city",
    region_ru: "г. Ташкент",
    region_uz: "Toshkent shahri",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe30243745e",
    region: "Tashkent region",
    region_ru: "Ташкентская область",
    region_uz: "Toshkent viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe30243745f",
    region: "Khorezm region",
    region_ru: "Хорезмская область",
    region_uz: "Xorazm viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437460",
    region: "Andijan region",
    region_ru: "Андижанская область",
    region_uz: "Andijon viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437461",
    region: "Karakalpakstan Republic",
    region_ru: "Республика Каракалпакстан",
    region_uz: "Qoraqalpog'iston Respublikasi",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437462",
    region: "Bukhara region",
    region_ru: "Бухарская область",
    region_uz: "Buxoro viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437463",
    region: "Fergana region",
    region_ru: "Ферганская область",
    region_uz: "Farg'ona viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437464",
    region: "Jizzakh region",
    region_ru: "Джизакская область",
    region_uz: "Jizzax viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437465",
    region: "Namangan region",
    region_ru: "Наманганская область",
    region_uz: "Namangan viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437466",
    region: "Navoi region",
    region_ru: "Навоийская область",
    region_uz: "Navoiy viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437467",
    region: "Kashkadarya region",
    region_ru: "Кашкадарьинская область",
    region_uz: "Qashqadaryo viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437468",
    region: "Samarkand region",
    region_ru: "Самаркандская область",
    region_uz: "Samarqand viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe302437469",
    region: "Syrdarya region",
    region_ru: "Сырдарьинская область",
    region_uz: "Sirdaryo viloyati",
    __v: 0,
  },
  {
    region_id: "671d30594130dfe30243746a",
    region: "Surkhandarya region",
    region_ru: "Сурхандарьинская область",
    region_uz: "Surxondaryo viloyati",
    __v: 0,
  },
];
