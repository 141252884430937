import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';

export function useGetTitle() {
    const { language } = useContext(LanguageContext);

    const translateTitle = (name) => {
        const langs = {
            en: `${name}`,
            ru: `${name}_ru`,
            uz: `${name}_uz`,
        };
        return langs[language];
    };

    return { translateTitle };
}
