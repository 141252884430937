// ? Domain
// export const baseURL = "http://localhost:5000/dev/v1/admin/";
export const baseURL = "https://com-api.edu-action.uk/examsuz/dev/v1/admin/";
export const mediaBaseURL = "https://media-api.main-gate.appx.uz";

// ? Login
export const authLogin = "auth/login";
export const authLogout = "auth/logout";
export const authRefresh = "auth/refresh";
export const authAdd = "admin/add";

// ? Menu
export const menuList = "/menu";
export const menuPost = "/menu";
export const menuPut = "/menu/";
export const menuDelete = "/menu/";
export const menuChangePosition = "/menu/position";

// ? Footer
export const footerList = "/footer";
export const footerPost = "/menu";
export const footerPut = "/menu/";
export const footerDelete = "/menu/";

// ? Pages
export const pagesList = "/pages";
export const pagesPost = "/pages";
export const pagesPut = "/pages/";
export const pagesDelete = "/pages/";

// ? News
export const newsList = "/news";
export const newsPost = "/news";
export const newsPut = "/news/";
export const newsDelete = "/news/";

// ? Banner
export const bannerList = "/banner";
export const bannerPost = "/banner";
export const bannerPut = "/banner/";
export const bannerDelete = "/banner/";

// ? Cards
export const cardList = "/card";
export const cardPost = "/card";
export const cardPut = "/card/";
export const cardDelete = "/card/";

// ? Videos
export const videoList = "/useful-video";
export const videoPost = "/useful-video";
export const videoPut = "/useful-video/";
export const videoDelete = "/useful-video/";

// ? Test Venue
export const testVenueList = "/test-venue";
export const testVenuePost = "/test-venue";
export const testVenuePut = "/test-venue/";
export const testVenueDelete = "/test-venue/";

// ? Partners
export const partnersList = "/partner";
export const partnersPost = "/partner";
export const partnersPut = "/partner/";
export const partnersDelete = "/partner/";

// ? Details
export const detailsList = "/detail";
export const detailsPost = "/detail";
export const detailsPut = "/detail/";
export const detailsDelete = "/detail/";

// ? Details Items
export const detailsItemList = "/detail_item";
export const detailsItemPost = "/detail_item";
export const detailsItemPut = "/detail_item/";
export const detailsItemDelete = "/detail_item/";
