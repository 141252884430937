import React, { useEffect } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { footerPost, footerPut } from "../../utils/url";

const FooterForm = ({ setOpen, data = null, request }) => {
  const postRequest = usePostRequest({ url: footerPost });
  const putRequest = usePutRequest({ url: `${footerPut}${data?._id}` });

  const [form] = Form.useForm();

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    if (data !== null) {
      await putRequest.request({
        data: Object.assign(values, { is_footer: 1 }),
        sync: true,
      });
    } else {
      await postRequest.request({
        data: Object.assign(values, { is_footer: 1 }),
        sync: true,
      });
    }
    await request();
    setOpen(false);
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) =>
    message.error(errorFields[0].errors[0]);

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="fixed-form"
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Form.Item {...formLabel("Title", "title")}>
            <Input {...formInput("title")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Title ru", "title_ru")}>
            <Input {...formInput("title_ru")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Title uz", "title_uz")}>
            <Input {...formInput("title_uz")} />
          </Form.Item>
        </Col>

        <Col span={24} style={{ marginTop: 10 }}>
          <Button
            htmlType="reset"
            type="default"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            Reset
          </Button>
        </Col>
        <Col span={24}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            {data !== null ? "Edit" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FooterForm;
