import { Image, Space, Table } from "antd";
import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DeleteModalBtn from "../components/Atoms/Buttons/DeleteModalBtn";
import EditDrawerBtn from "../components/Atoms/Buttons/EditDrawerBtn";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";
import ImageWrapper from "../components/Atoms/ImageWrapper/ImageWrapper";
import { useLoad } from "../hooks/request";
import { useGetTitle } from "../hooks/useGetTitle";
import useLanguage from "../hooks/useLanguage";
import { partnersDelete, partnersList } from "../utils/url";

export default function PartnersPage() {
  const { translateTitle } = useGetTitle();
  const getRequest = useLoad({ url: partnersList });
  const { response, loading } = getRequest;
  const translate = useLanguage();

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (record) => (
        <ImageWrapper>
          <Image
            width="100%"
            height="100%"
            src={record}
            alt="logo"
            style={{ objectFit: "cover" }}
          />
        </ImageWrapper>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: translateTitle("description"),
      key: "description",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn
            type={"partners"}
            data={record}
            request={getRequest.request}
          />
          <DeleteModalBtn
            url={partnersDelete}
            id={record._id}
            request={getRequest.request}
          />
        </Space>
      ),
    },
  ];

  return (
    <section className="page">
      <header className="page__header">
        <h1 className="page__title">Partners</h1>
        <PostDrawerBtn type={"partners"} request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-partner")}
        </PostDrawerBtn>
      </header>
      <div className="page__content">
        <Table
          columns={columns}
          dataSource={response?.data?.partners}
          bordered
          pagination={{
            pageSize: 10,
            total: response?.data?.pagination?.total,
            onChange: (page) => {
              getRequest.request({
                url: `${partnersDelete}?page=${page}&size=10`,
              });
            },
          }}
          rowKey="id"
          loading={loading}
        />
      </div>
    </section>
  );
}
