import { Drawer } from 'antd';
import React from 'react';
import useLanguage from '../../hooks/useLanguage';
import BannerForm from '../Forms/BannerForm';
import CardsForm from '../Forms/CardsForm';
import DetailsForm from '../Forms/DetailsForm';
import DetailsItemForm from '../Forms/DetailsItemForm';
import FooterForm from '../Forms/FooterForm';
import MenuForm from '../Forms/MenuForm';
import NewsForm from '../Forms/NewsForm';
import PagesForm from '../Forms/PagesForm';
import PartnersForm from '../Forms/PartnersForm';
import TestVenueForm from '../Forms/TestVenueForm';
import VideosForm from '../Forms/VideosForm';

const UpdateDrawer = ({
    open,
    setOpen,
    type,
    data,
    request,
    page_id,
    detail_id,
    detail_type,
}) => {
    const translate = useLanguage();
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Drawer
            title={translate('update')}
            placement="right"
            onClose={onClose}
            open={open}
            width={
                detail_type === 'grid'
                    ? '40vw'
                    : type === 'pages' ||
                      type === 'news' ||
                      type === 'banner' ||
                      type === 'cards' ||
                      type === 'videos'
                    ? '82vw'
                    : null
            }>
            {type === 'menu' && (
                <MenuForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'footer' && (
                <FooterForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'pages' && (
                <PagesForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'news' && (
                <NewsForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'banner' && (
                <BannerForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'cards' && (
                <CardsForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'videos' && (
                <VideosForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'test-venue' && (
                <TestVenueForm
                    setOpen={setOpen}
                    data={data}
                    request={request}
                />
            )}
            {type === 'partners' && (
                <PartnersForm setOpen={setOpen} data={data} request={request} />
            )}
            {type === 'details' && (
                <DetailsForm
                    setOpen={setOpen}
                    data={data}
                    request={request}
                    page_id={page_id}
                />
            )}
            {type === 'details-item' && (
                <DetailsItemForm
                    setOpen={setOpen}
                    data={data}
                    request={request}
                    detail_id={detail_id}
                    detail_type={detail_type}
                />
            )}
        </Drawer>
    );
};

export default UpdateDrawer;
