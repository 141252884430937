import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import useLanguage from '../../../hooks/useLanguage';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDeleteRequest } from '../../../hooks/request';

const DeleteModalBtn = ({ url, id, request }) => {
    const deleteRequest = useDeleteRequest({ url: `${url}${id}` });
    const [open, setOpen] = useState(false);
    const translate = useLanguage();

    const onOk = async () => {
        try {
            await deleteRequest.request();
            setOpen(false);
            await request();
            message.success('Success!');
        } catch (error) {
            message.error(error.toString());
        }
    };

    const onCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                type="default"
                danger
                className="delete__btn btn"
                loading={deleteRequest.loading}
                onClick={() => setOpen(true)}>
                {!deleteRequest.loading && (
                    <RiDeleteBinLine className="btn__icon icon" />
                )}
                {translate('delete')}
            </Button>
            <Modal
                title={translate('delete-confirm')}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
                okType="danger"
                centered
                confirmLoading={deleteRequest?.loading}
            />
        </>
    );
};

export default DeleteModalBtn;
