import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import { Space } from "antd";
import clsx from "clsx";
import PagesBottomItem from "./PagesBottomItem";
import useLanguage from "../../hooks/useLanguage";
import { pagesDelete } from "../../utils/url";
import DeleteModalBtn from "../Atoms/Buttons/DeleteModalBtn";
import EditDrawerBtn from "../Atoms/Buttons/EditDrawerBtn";
import PostDrawerBtn from "../Atoms/Buttons/PostDrawerBtn";
import { useGetTitle } from "../../hooks/useGetTitle";

export default function PagesItem({ item, request }) {
  const { translateTitle } = useGetTitle();
  const [open, setOpen] = useState(false);
  const translate = useLanguage();

  return (
    <div className="pages-wrap__item">
      <div className={clsx("pages-wrap__item-header", open && "active")}>
        <p className="pages-wrap__title">{item[translateTitle("title")]}</p>
        <Space className="crud__actions">
          <IoIosArrowDropdown
            className={clsx("icon", open && "active")}
            onClick={() => setOpen(!open)}
          />

          <EditDrawerBtn type={"pages"} data={item} request={request} />
          <DeleteModalBtn url={pagesDelete} id={item._id} request={request} />
        </Space>
      </div>
      <div
        className={clsx(
          "pages-wrap__item-bottom pages-bottom",
          open && "active"
        )}
      >
        <header className="page__header pages-bottom__header">
          <p className="pages-bottom__title">Details</p>
          <PostDrawerBtn type={"details"} request={request} page_id={item._id}>
            <AiOutlinePlusCircle className="icon" />
            {translate("add-detail")}
          </PostDrawerBtn>
        </header>
        <div className="pages-bottom__items">
          {item?.details?.map((subItem) => (
            <PagesBottomItem
              key={subItem._id}
              item={subItem}
              request={request}
              page_id={item._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
