import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import useLanguage from "../hooks/useLanguage";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";
import MenuDnD from "../components/DragAndDrop/MenuDnD/MenuDnD";
import { useLoad } from "../hooks/request";
import { menuList } from "../utils/url";

const MenuPage = () => {
  const getRequest = useLoad({ url: menuList });
  const translate = useLanguage();

  return (
    <section className="page menu-page">
      <header className="page__header">
        <h1 className="page__title">Menu</h1>
        <PostDrawerBtn type={"menu"} request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-menu")}
        </PostDrawerBtn>
      </header>
      <div className="page__content menu-dnd">
        <MenuDnD
          response={getRequest.response}
          request={getRequest.request}
          loading={getRequest.loading}
        />
      </div>
    </section>
  );
};

export default MenuPage;
