export const flattenMenus = (menus = []) => {
  let flatList = [];

  const flatten = (menuItems) => {
    for (const item of menuItems) {
      // Add the current menu item to the flat list
      flatList.push(item);
      // Recursively flatten children if they exist
      if (item.children && item.children.length > 0) {
        flatten(item.children);
      }
    }
  };

  flatten(menus);
  return flatList;
};
