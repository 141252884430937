import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from '../page/LoginPage';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

export default AuthRoutes;
