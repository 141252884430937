import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Button, Col, Form, Input, message, Row, Space, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import ImageUpload from "../ImageUpload";
import { newsPost, newsPut } from "../../utils/url";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { createSlug } from "../../utils/utils";
import { quillFormats, quillModules } from "../../utils/quill-modules";

export default function NewsForm({ setOpen, data = null, request }) {
  const postRequest = usePostRequest({ url: newsPost });
  const putRequest = usePutRequest({ url: `${newsPut}${data?._id}` });
  const [body, setBody] = useState("");

  const [form] = Form.useForm();

  const handleBody = (e) => {
    setBody(e);
  };

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    const req = {
      title: values.title,
      title_ru: values.title_ru,
      title_uz: values.title_uz,
      subtitle: values.subtitle,
      subtitle_ru: values.subtitle_ru,
      subtitle_uz: values.subtitle_uz,
      content: values.content,
      content_ru: values.content_ru,
      content_uz: values.content_uz,
      image: values.image,
      slug: createSlug(values.title),
    };
    if (data !== null) {
      await putRequest.request({
        data: req,
        sync: true,
      });
    } else {
      await postRequest.request({
        data: req,
        sync: true,
      });
    }
    await request();
    setOpen(false);
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] === "image") {
      return message.error("Please upload Image!");
    } else {
      return message.error(errorFields[0].errors[0]);
    }
  };

  const items = [
    {
      key: "1",
      label: "EN",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle", "subtitle")}>
              <TextArea
                {...formInput("subtitle")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content", "content")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "RU",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle Ru", "subtitle_ru")}>
              <TextArea
                {...formInput("subtitle_ru")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content Ru", "content_ru")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content_ru")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: "UZ",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle Uz", "subtitle_uz")}>
              <TextArea
                {...formInput("subtitle_uz")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content Uz", "content_uz")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content_uz")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="page-fullform"
    >
      <section className="tabs">
        <Tabs
          type="card"
          tabBarExtraContent={
            <Space style={{ marginBottom: 5 }}>
              <Button
                htmlType="reset"
                type="default"
                loading={postRequest.loading || putRequest.loading}
              >
                Reset
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={postRequest.loading || putRequest.loading}
              >
                {data !== null ? "Edit" : "Submit"}
              </Button>
            </Space>
          }
          items={items}
        />
      </section>
      <section className="page-fullform__aside">
        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: true,
              message: "Please upload Image!",
            },
          ]}
        >
          <ImageUpload name="image" data={data} form={form} />
        </Form.Item>
      </section>
    </Form>
  );
}
