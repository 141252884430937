import React, { useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Space, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { bannerPost, bannerPut } from "../../utils/url";
import ImageUpload from "../ImageUpload";

export default function BannerForm({ setOpen, data = null, request }) {
  const postRequest = usePostRequest({ url: bannerPost });
  const putRequest = usePutRequest({ url: `${bannerPut}${data?._id}` });
  const [form] = Form.useForm();

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    if (data !== null) {
      await putRequest.request({
        data: values,
        sync: true,
      });
    } else {
      await postRequest.request({
        data: values,
        sync: true,
      });
    }
    await request();
    setOpen(false);
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] === "image") {
      return message.error("Please upload Image!");
    } else {
      return message.error(errorFields[0].errors[0]);
    }
  };

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  const items = [
    {
      key: "tab1",
      label: "EN",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description", "description")}>
              <TextArea
                {...formInput("description")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab2",
      label: "RU",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description Ru", "description_ru")}>
              <TextArea
                {...formInput("description_ru")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab3",
      label: "UZ",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description Uz", "description_uz")}>
              <TextArea
                {...formInput("description_uz")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="fixed-form"
    >
      <Tabs
        type="card"
        tabBarExtraContent={
          <Space style={{ marginBottom: 5 }}>
            <Button
              htmlType="reset"
              type="default"
              loading={postRequest.loading || putRequest.loading}
            >
              Reset
            </Button>

            <Button
              htmlType="submit"
              type="primary"
              loading={postRequest.loading || putRequest.loading}
            >
              {data !== null ? "Edit" : "Submit"}
            </Button>
          </Space>
        }
        items={items}
      />
      <Row>
        <Col span={24}>
          <Form.Item
            label="Image"
            name="image"
            rules={[
              {
                required: true,
                message: "Please upload Image!",
              },
            ]}
          >
            <ImageUpload name="image" data={data} form={form} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
