import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import useLanguage from "../hooks/useLanguage";
import { useLoad } from "../hooks/request";
import { pagesList } from "../utils/url";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";
import PagesItem from "../components/PagesItems/PagesItem";
import PagePagination from "../components/Atoms/Pagination/Pagination";
import LoadingSpin from "../components/Atoms/LoadingSpin";

const PagesPage = () => {
  const [current, setCurrent] = useState(1);
  const translate = useLanguage();
  const getRequest = useLoad({ url: `${pagesList}?page=${current}` }, [
    current,
  ]);

  return (
    <section className="page">
      <header className="page__header">
        <h1 className="page__title">Pages</h1>
        <PostDrawerBtn type={"pages"} request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-page")}
        </PostDrawerBtn>
      </header>
      <div className="page__content pages-wrap">
        <LoadingSpin loading={getRequest.loading}>
          <div className="pages-wrap__items">
            {getRequest?.response?.data?.pages?.map((item) => (
              <PagesItem
                key={item._id}
                item={item}
                request={getRequest.request}
              />
            ))}
          </div>
          {!getRequest.loading && (
            <PagePagination
              total={getRequest?.response?.data?.pagination?.total}
              current={current}
              setCurrent={setCurrent}
            />
          )}
        </LoadingSpin>
      </div>
    </section>
  );
};

export default PagesPage;
