import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useLoad, usePostRequest, usePutRequest } from "../../hooks/request";
import { pagesList, videoList, videoPut } from "../../utils/url";
import ImageUpload from "../ImageUpload";

export default function VideosForm({ setOpen, data = null, request }) {
  const postRequest = usePostRequest({ url: videoList });
  const putRequest = usePutRequest({ url: `${videoPut}${data?._id}` });
  const getRequest = useLoad({ url: pagesList });
  const { response } = getRequest;
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState(false);
  const [form] = Form.useForm();

  const handleCheck = () => {
    setCheck(!check);
  };

  const handleStatus = () => {
    setStatus(!status);
  };

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    if (data !== null) {
      await putRequest.request({
        data: Object.assign(values, {
          page_id: values.page_id != 0 ? values.page_id : null,
          is_home: check ? 1 : 0,
          status: status ? 1 : 0,
        }),
        sync: true,
      });
    } else {
      await postRequest.request({
        data: Object.assign(values, {
          page_id: values.page_id != 0 ? values.page_id : null,
          is_home: check ? 1 : 0,
          status: status ? 1 : 0,
        }),
        sync: true,
      });
    }
    await request();
    setOpen(false);
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] === "icon") {
      return message.error("Please upload Icon!");
    } else {
      return message.error(errorFields[0].errors[0]);
    }
  };

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue(data);
      if (data?.is_home === 1) {
        if (data?.status === 1) setStatus(true);
        setCheck(true);
      }
    }
  }, []);

  const items = [
    {
      key: "tab1",
      label: "EN",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description", "description")}>
              <TextArea
                {...formInput("description")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab2",
      label: "RU",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description Ru", "description_ru")}>
              <TextArea
                {...formInput("description_ru")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab3",
      label: "UZ",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Description Uz", "description_uz")}>
              <TextArea
                {...formInput("description_uz")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="fixed-form page-fullform"
    >
      <section className="tabs">
        <Tabs
          type="card"
          tabBarExtraContent={
            <Space style={{ marginBottom: 5 }}>
              <Button
                htmlType="reset"
                type="default"
                loading={postRequest.loading || putRequest.loading}
              >
                Reset
              </Button>

              <Button
                htmlType="submit"
                type="primary"
                loading={postRequest.loading || putRequest.loading}
              >
                {data !== null ? "Edit" : "Submit"}
              </Button>
            </Space>
          }
          items={items}
        />
      </section>
      <section className="page-fullform__aside">
        <Row>
          <Col span={24}>
            <Form.Item
              label="URl link"
              name="youtube_link"
              rules={[
                {
                  required: true,
                  message: "Please input URL link!",
                },
              ]}
            >
              <Input {...formInput("page")} />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Form.Item
              label="Page URL"
              name="page"
              rules={[
                {
                  required: true,
                  message: "Please input Page URL!",
                },
                {
                  type: "url",
                  warningOnly: true,
                },
                {
                  type: "string",
                  min: 6,
                },
              ]}
            >
              <Input {...formInput("youtube_link")} />
            </Form.Item>
          </Col>
          <Divider />
          <Col>
            <Form.Item {...formLabel("Page ID", "page_id")} initialValue={0}>
              <Select size="middle" width="100%">
                <Select.Option key={34} value={0}>
                  No parent
                </Select.Option>
                {response?.data?.pages?.map((item) => {
                  return (
                    <Select.Option key={item._id} value={item._id}>
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Checkbox onChange={handleStatus} checked={status}>
              Status
            </Checkbox>
          </Col>
          <Divider />
          <Col span={24}>
            <Checkbox onChange={handleCheck} checked={check}>
              For home page
            </Checkbox>
          </Col>
          <Divider />
          <Col span={24}>
            <Form.Item
              label="Image"
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload Image!",
                },
              ]}
            >
              <ImageUpload name="image" data={data} form={form} />
            </Form.Item>
          </Col>
        </Row>
      </section>
    </Form>
  );
}
