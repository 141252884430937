import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { quillFormats, quillModules } from "../../utils/quill-modules";
import { detailsItemPost, detailsItemPut } from "../../utils/url";

export default function DetailsItemForm({
  setOpen,
  data = null,
  request,
  detail_id,
  detail_type,
}) {
  const postRequest = usePostRequest({ url: detailsItemPost });
  const putRequest = usePutRequest({ url: `${detailsItemPut}${data?._id}` });
  const [body, setBody] = useState("");

  const handleBody = (e) => {
    setBody(e);
  };

  const [form] = Form.useForm();

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const onFinish = async (values) => {
    const req = Object.assign(values, { detail_id: detail_id });

    if (data !== null) {
      await putRequest.request({
        data: req,
        sync: true,
      });
    } else {
      await postRequest.request({
        data: req,
        sync: true,
      });
    }
    setOpen(false);
    await request();
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) =>
    message.error(errorFields[0].errors[0]);

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue(data);
    }
  }, []);
  const items = [
    {
      label: "EN",
      key: "en",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} />
            </Form.Item>
          </Col>

          {(detail_type === "accordion" || detail_type === "grid") && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle", "subtitle")}>
                <Input {...formInput("subtitle")} />
              </Form.Item>
            </Col>
          )}

          {detail_type === "grid" && (
            <Col span={24}>
              <Form.Item {...formLabel("Content", "content")}>
                <ReactQuill
                  placeholder="Type here"
                  modules={quillModules}
                  formats={quillFormats}
                  onChange={handleBody}
                  value={body}
                  {...formInput("content")}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
    {
      label: "RU",
      key: "ru",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>

          {(detail_type === "accordion" || detail_type === "grid") && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle Ru", "subtitle_ru")}>
                <Input {...formInput("subtitle_ru")} />
              </Form.Item>
            </Col>
          )}

          {detail_type === "grid" && (
            <Col span={24}>
              <Form.Item {...formLabel("Content Ru", "content_ru")}>
                <ReactQuill
                  placeholder="Type here"
                  modules={quillModules}
                  formats={quillFormats}
                  onChange={handleBody}
                  value={body}
                  {...formInput("content_ru")}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
    {
      label: "UZ",
      key: "uz",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>

          {(detail_type === "accordion" || detail_type === "grid") && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle Uz", "subtitle_uz")}>
                <Input {...formInput("subtitle_uz")} />
              </Form.Item>
            </Col>
          )}

          {detail_type === "grid" && (
            <Col span={24}>
              <Form.Item {...formLabel("Content Uz", "content_uz")}>
                <ReactQuill
                  placeholder="Type here"
                  modules={quillModules}
                  formats={quillFormats}
                  onChange={handleBody}
                  value={body}
                  {...formInput("content_uz")}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className={"fixed-form"}
    >
      <Row>
        <Col span={24}>
          <Tabs
            type="card"
            tabPosition="top"
            items={items}
            tabBarExtraContent={
              detail_type === "grid" && (
                <Space style={{ marginBottom: 7 }}>
                  <Button
                    htmlType="reset"
                    type="default"
                    loading={postRequest.loading || putRequest.loading}
                    block
                  >
                    Reset
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={postRequest.loading || putRequest.loading}
                    block
                  >
                    {data !== null ? "Edit" : "Submit"}
                  </Button>
                </Space>
              )
            }
          />
        </Col>
        <Divider />
        {detail_type === "url" && (
          <>
            <Col span={24}>
              <Form.Item
                name="link"
                label="Url"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "url",
                    warningOnly: true,
                  },
                  {
                    type: "string",
                    min: 6,
                  },
                ]}
              >
                <Input {...formInput("link")} />
              </Form.Item>
            </Col>
            <Divider />
          </>
        )}
        {detail_type !== "grid" && (
          <>
            <Col span={24} style={{ marginBottom: 10 }}>
              <Button
                htmlType="reset"
                type="default"
                loading={postRequest.loading || putRequest.loading}
                block
              >
                Reset
              </Button>
            </Col>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                loading={postRequest.loading || putRequest.loading}
                block
              >
                {data !== null ? "Edit" : "Submit"}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
}
