import { AES } from 'crypto-js';
import slugify from 'slugify';

export const handleEncrypted = () =>
    AES.encrypt(
        JSON.stringify({
            client: 'ecommerce',
            secret: 'gCosGwTqCNCpIoGnS28V7TfD2V0obDbPaJSY6LvmN7Lg0XPl5Rt6ne9vdbwL+Q',
            time: Date.now(),
        }),
        'G2DPdL0RN2ldSRuKpnWSRlfZrzBBEtc0qhZ+xQaRjjdTZdV89bausl1KR6l1SkqY',
    ).toString();

export const getDeleteKey = (url) => {
    const loc = new URL(url);
    const deleteKey = loc.pathname.slice(1);

    return deleteKey;
};

export const createSlug = (name) => {
    return slugify(name, {
        replacement: '-',
        lower: true,
        trim: true,
        strict: true,
        locale: 'en',
        limit: 7
    });
};
