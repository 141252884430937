import React, { useState } from 'react';
import { Button } from 'antd';
import PostDrawer from '../../Drawers/PostDrawer';

const PostDrawerBtn = ({
    children,
    type,
    request,
    page_id,
    detail_id,
    detail_type,
}) => {
    const [open, setOpen] = useState(false);

    const handleChange = () => {
        setOpen(true);
    };

    return (
        <>
            <Button
                type="primary"
                className="header__btn btn"
                onClick={handleChange}>
                {children}
            </Button>
            {open && (
                <PostDrawer
                    open={open}
                    setOpen={setOpen}
                    type={type}
                    request={request}
                    page_id={page_id}
                    detail_id={detail_id}
                    detail_type={detail_type}
                />
            )}
        </>
    );
};

export default PostDrawerBtn;
