import { Button, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
// ! Pages
import MenuPage from '../page/MenuPage';
import FooterPage from '../page/FooterPage';
import PagesPage from '../page/PagesPage';
import NewsPage from '../page/NewsPage';
import BannerPage from '../page/BannerPage';
import CardsPage from '../page/CardsPage';
import VideosPage from '../page/VideosPage';
import TestVenuePage from '../page/TestVenuePage';
import PartnersPage from '../page/PartnersPage';
import LogoutPage from '../page/LogoutPage';
// ? Icons
import {
    AiOutlineFileText,
    AiOutlineIdcard,
    AiOutlinePicCenter,
    AiOutlinePicture,
    AiOutlineCreditCard,
    AiOutlineBank,
    AiOutlineMenuFold,
    AiOutlineMenuUnfold,
} from 'react-icons/ai';
import { BiNews } from 'react-icons/bi';
import { CgMenuBoxed } from 'react-icons/cg';
import { MdOutlineLogout, MdOutlineOndemandVideo } from 'react-icons/md';
// * Others
import LangSelect from '../components/LanguageSelect';
import useLanguage from '../hooks/useLanguage';
import { LogoSvg } from '../components/Icons';

function getItem(label, key, icon, children, type) {
    return {
        label,
        key,
        icon,
        children,
        type,
    };
}

const AppRoutes = () => {
    const navigate = useNavigate();
    const [collapse, setCollapse] = useState(false);
    const translate = useLanguage();

    const items = [
        getItem(
            <span className="menu__icon">{!collapse && 'Menu'}</span>,
            '/menyu',
            <CgMenuBoxed className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Footer'}</span>,
            '/footer',
            <AiOutlinePicCenter className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Pages'}</span>,
            '/pages',
            <AiOutlineFileText className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'News'}</span>,
            '/news',
            <BiNews className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Banner'}</span>,
            '/banner',
            <AiOutlinePicture className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Cards'}</span>,
            '/cards',
            <AiOutlineCreditCard className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Videos'}</span>,
            '/videos',
            <MdOutlineOndemandVideo className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Test venue'}</span>,
            '/test-venue',
            <AiOutlineBank className="icon" />,
        ),
        getItem(
            <span className="menu__icon">{!collapse && 'Partners'}</span>,
            '/partners',
            <AiOutlineIdcard className="icon" />,
        ),
    ];

    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    return (
        <Layout className={clsx('wrapper layout', collapse && 'active')}>
            <header className="header container">
                <Link to="/">
                    <LogoSvg />
                </Link>
                <div className="header__items">
                    <LangSelect />
                    <Button type="default" danger className="header__logout">
                        <Link to="/logout" className="header__link">
                            <MdOutlineLogout className="icon" />
                            {translate('logout')}
                        </Link>
                    </Button>
                </div>
            </header>
            <aside className={clsx('sidebar', collapse && 'active')}>
                <Menu
                    className="sidebar__menu"
                    onClick={({ key }) => navigate(key)}
                    items={items}
                    selectedKeys={window.location.pathname}
                    mode="inline"
                />
                <Button
                    type="primary"
                    block
                    className="collapse"
                    onClick={handleCollapse}>
                    {collapse ? (
                        <AiOutlineMenuUnfold className="icon" />
                    ) : (
                        <span className="collapse__content">
                            {translate('collapse')}
                            <AiOutlineMenuFold className="icon" />
                        </span>
                    )}
                </Button>
            </aside>
            <main className="main">
                <div className="main__container">
                    <Routes>
                        <Route path="/menyu" element={<MenuPage />} />
                        <Route path="/footer" element={<FooterPage />} />
                        <Route path="/pages" element={<PagesPage />} />
                        <Route path="/news" element={<NewsPage />} />
                        <Route path="/banner" element={<BannerPage />} />
                        <Route path="/cards" element={<CardsPage />} />
                        <Route path="/videos" element={<VideosPage />} />
                        <Route path="/test-venue" element={<TestVenuePage />} />
                        <Route path="/partners" element={<PartnersPage />} />
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="/" element={<Navigate to="/menyu" />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </main>
        </Layout>
    );
};

export default AppRoutes;
