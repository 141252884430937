import React from 'react';
import { Row, Pagination } from 'antd';

export default function PagePagination({ total, current, setCurrent }) {
    const onChange = (page) => {
        setCurrent(page);
    };

    return (
        <Row align="middle" justify="end" style={{ marginBlock: 20 }}>
            <Pagination current={current} onChange={onChange} total={total} />
        </Row>
    );
}
