import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Row } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { usePostRequest } from '../hooks/request';
import { authLogin } from '../utils/url';

const LoginPage = () => {
    const loginPost = usePostRequest({ url: authLogin });
    const { loading } = loginPost;
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const formLabel = (title, name) => ({
        label: title,
        name: name,
        rules: [
            {
                required: true,
                message: `Please input your ${name}!`,
            },
        ],
    });

    const formInput = (name) => ({
        placeholder: 'Type here',
        name: name,
    });

    const onFinish = async (values) => {
        const { response } = await loginPost.request({
            data: values,
            sync: true,
        });
        if (response.success) {
            const { accessToken, refreshToken } = response.data.user;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            message.success('Success!');
            setUser(true);
            navigate('/');
        }
        if (!response.success) {
            return message.error('Incorrect login or password!');
        }
    };

    return (
        <Row align="middle" justify="center" style={{ height: '100vh' }}>
            <Card title="Login" size="default" style={{ width: 320 }}>
                <Form onFinish={onFinish} form={form}>
                    <Form.Item {...formLabel('', 'username')}>
                        <Input
                            autoComplete="off"
                            prefix={<UserOutlined />}
                            {...formInput('Username')}
                        />
                    </Form.Item>
                    <Form.Item {...formLabel('', 'password')}>
                        <Input.Password
                            autoComplete="off"
                            prefix={<LockOutlined />}
                            {...formInput('Password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            block>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Row>
    );
};

export default LoginPage;
