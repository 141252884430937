import React, { useState } from 'react';
import { Button } from 'antd';
import useLanguage from '../../../hooks/useLanguage';
import { BiEditAlt } from 'react-icons/bi';
import UpdateDrawer from '../../Drawers/UpdateDrawer';

const EditDrawerBtn = ({
    type,
    data,
    request,
    page_id,
    detail_id,
    detail_type,
}) => {
    const [open, setOpen] = useState(false);
    const translate = useLanguage();

    return (
        <>
            <Button
                type="default"
                className="edit__btn btn"
                onClick={() => setOpen(true)}>
                <BiEditAlt className="btn__icon icon" />
                {translate('edit')}
            </Button>
            {open && (
                <UpdateDrawer
                    open={open}
                    setOpen={setOpen}
                    type={type}
                    data={data}
                    request={request}
                    page_id={page_id}
                    detail_id={detail_id}
                    detail_type={detail_type}
                />
            )}
        </>
    );
};

export default EditDrawerBtn;
