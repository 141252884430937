import { Button, Col, Form, Input, message, Row, Select } from "antd";
import React, { useEffect } from "react";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { useGetTitle } from "../../hooks/useGetTitle";
import { regionData } from "../../utils/data";
import { testVenueList, testVenuePut } from "../../utils/url";
import ImageUpload from "../ImageUpload";

export default function TestVenueForm({ setOpen, data = null, request }) {
  const { translateTitle } = useGetTitle();
  const postRequest = usePostRequest({ url: testVenueList });
  const putRequest = usePutRequest({ url: `${testVenuePut}${data?._id}` });

  const [form] = Form.useForm();

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    const selectRegion = regionData.filter((x) => x.region === values.region);

    const req = {
      name: values.name,
      address: values.address,
      location: values.location,
      photo: values.photo,
      region: values.region,
      region_id: selectRegion[0].region_id,
      region_ru: selectRegion[0].region_ru,
      region_uz: selectRegion[0].region_uz,
    };

    try {
      if (data !== null) {
        await putRequest.request({
          data: req,
          sync: true,
        });
      } else {
        await postRequest.request({
          data: req,
          sync: true,
        });
      }
      await request();
      setOpen(false);
      message.success("Success!");
    } catch (error) {
      message.error(error.message);
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] === "photo") {
      return message.error("Please upload Photo!");
    } else {
      return message.error(errorFields[0].errors[0]);
    }
  };

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="fixed-form"
    >
      <Row>
        <Col span={24}>
          <Form.Item {...formLabel("Name", "name")}>
            <Input {...formInput("name")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...formLabel("Address", "address")}>
            <Input {...formInput("address")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please input Location!",
              },
              {
                type: "url",
                warningOnly: true,
              },
              {
                type: "string",
                min: 6,
              },
            ]}
          >
            <Input {...formInput("location")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Region", "region")}>
            <Select size="middle" width="100%">
              <Select.Option value=""></Select.Option>
              {regionData.map((item) => {
                return (
                  <Select.Option key={item.region_id} value={item.region}>
                    {item[translateTitle("region")]}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item {...formLabel("Photo", "photo")}>
            <ImageUpload name="photo" data={data} form={form} />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginBlock: 10 }}>
          <Button
            type="default"
            htmlType="reset"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            Reset
          </Button>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            {data !== null ? "Edit" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
