import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import './styles/main.scss';
import UserContextProvider from './context/UserContext';
import LanguageProvider from './context/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <LanguageProvider>
            <UserContextProvider>
                <App />
            </UserContextProvider>
        </LanguageProvider>
    </BrowserRouter>,
);
