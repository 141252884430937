import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tabs,
} from "antd";
import { usePostRequest, usePutRequest } from "../../hooks/request";
import { detailsPost, detailsPut } from "../../utils/url";
import useLanguage from "../../hooks/useLanguage";

export default function DetailsForm({
  setOpen,
  data = null,
  request,
  page_id,
}) {
  const [check, setCheck] = useState(false);
  const translate = useLanguage();
  const postRequest = usePostRequest({ url: detailsPost });
  const putRequest = usePutRequest({ url: `${detailsPut}${data?._id}` });

  const handleCheck = () => {
    setCheck(!check);
  };

  const [form] = Form.useForm();

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const onFinish = async (values) => {
    const req = Object.assign(values, {
      page_id: page_id,
      is_subtitle: check,
    });

    if (data !== null) {
      await putRequest.request({
        data: req,
        sync: true,
      });
    } else {
      await postRequest.request({
        data: req,
        sync: true,
      });
    }
    setOpen(false);
    await request();
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) =>
    message.error(errorFields[0].errors[0]);

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue(data);
      if (data?.is_subtitle === true) setCheck(true);
    }
  }, []);

  const items = [
    {
      label: "EN",
      key: "en",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} />
            </Form.Item>
          </Col>
          {check && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle", "subtitle")}>
                <Input {...formInput("subtitle")} />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
    {
      label: "RU",
      key: "ru",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>
          {check && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle Ru", "subtitle_ru")}>
                <Input {...formInput("subtitle_ru")} />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
    {
      label: "UZ",
      key: "uz",
      forceRender: true,
      children: (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>
          {check && (
            <Col span={24}>
              <Form.Item {...formLabel("Subtitle Ru", "subtitle_uz")}>
                <Input {...formInput("subtitle_uz")} />
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className={"fixed-form"}
    >
      <Row>
        <Col span={24}>
          <Tabs type="card" tabPosition="top" items={items} />
        </Col>
        <Divider />
        <Checkbox onChange={handleCheck} checked={check}>
          {translate("have-a-subtitle")}
        </Checkbox>
        <Divider />
        <Col span={24}>
          <Form.Item {...formLabel("Type", "type")}>
            <Select size="middle" width="100%">
              <Select.Option value=""></Select.Option>
              <Select.Option value="accordion">Accordion</Select.Option>
              <Select.Option value="grid">Grid</Select.Option>
              <Select.Option value="url">Url</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24} style={{ marginBottom: 10 }}>
          <Button
            htmlType="reset"
            type="default"
            loading={postRequest.loading || putRequest.loading}
            block
          >
            Reset
          </Button>
        </Col>
        <Col span={24}>
          <Button
            htmlType="submit"
            type="primary"
            loading={postRequest.loading || putRequest.loading}
            block
          >
            {data !== null ? "Edit" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
