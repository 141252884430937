import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { menuList, pagesPost, pagesPut } from "../../utils/url";
import ImageUpload from "../ImageUpload";
import { useLoad, usePostRequest, usePutRequest } from "../../hooks/request";
import { createSlug } from "../../utils/utils";
import { quillFormats, quillModules } from "../../utils/quill-modules";
import { flattenMenus } from "../../utils/flattenMenus";

const { Title } = Typography;

const PagesForm = ({ setOpen, data = null, request }) => {
  const postRequest = usePostRequest({ url: pagesPost });
  const putRequest = usePutRequest({ url: `${pagesPut}${data?._id}` });
  const getRequest = useLoad({ url: menuList });
  const { response } = getRequest;
  const [body, setBody] = useState("");
  const [check, setCheck] = useState(false);
  const [slug, setSlug] = useState("");

  const [form] = Form.useForm();

  const handleBody = (e) => {
    setBody(e);
  };

  const handleCheckbox = () => {
    setCheck(!check);
    form.setFieldValue({
      is_link: check ? "1" : "0",
    });
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    form.setFieldsValue({ title: newTitle });
    const newSlug = createSlug(newTitle);
    setSlug(newSlug);
    form.setFieldsValue({ slug: newSlug });
  };

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    const req = {
      title: values.title,
      title_ru: values.title_ru,
      title_uz: values.title_uz,
      subtitle: values.subtitle,
      subtitle_ru: values.subtitle_ru,
      subtitle_uz: values.subtitle_uz,
      content: values.content,
      content_ru: values.content_ru,
      content_uz: values.content_uz,
      slug: values.slug,
      is_link: check ? 1 : 0,
      image: values.image,
      link: {
        title: values.link_title || "",
        title_ru: values.link_title_ru || "",
        title_uz: values.link_title_uz || "",
        url: values.link_url || "",
      },
      ...(values.menu_id != 0 ? { menu_id: values.menu_id } : {}),
    };
    const { success, error } = await (data !== null
      ? putRequest.request({
          data: req,
          sync: true,
        })
      : postRequest.request({
          data: req,
          sync: true,
        }));

    if (success) {
      request();
      setOpen(false);
      message.success("Success!");
    } else {
      message.error(error.message);
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields[0].name[0] === "image") {
      return message.error("Please upload Image!");
    } else {
      return message.error(errorFields[0].errors[0]);
    }
  };

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue(
        Object.assign(data, {
          link_title: data?.link?.title,
          link_title_ru: data?.link?.title_ru,
          link_title_uz: data?.link?.title_uz,
          link_url: data?.link?.url,
        })
      );
      if (data?.is_link === 1) setCheck(true);
    }
  }, []);

  const items = [
    {
      key: "tab1",
      label: "EN",
      forceRender: true,
      children: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title", "title")}>
              <Input {...formInput("title")} onChange={handleTitleChange} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Slug", "slug")}>
              <Input value={slug} onChange={handleSlugChange} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle", "subtitle")}>
              <TextArea
                {...formInput("subtitle")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content", "content")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab2",
      label: "RU",
      forceRender: true,
      children: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Ru", "title_ru")}>
              <Input {...formInput("title_ru")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle Ru", "subtitle_ru")}>
              <TextArea
                {...formInput("subtitle_ru")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content Ru", "content_ru")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: "tab3",
      label: "UZ",
      forceRender: true,
      children: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item {...formLabel("Title Uz", "title_uz")}>
              <Input {...formInput("title_uz")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Subtitle Uz", "subtitle_uz")}>
              <TextArea
                {...formInput("subtitle_uz")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formLabel("Content Uz", "content_uz")}>
              <ReactQuill
                placeholder="Type here"
                modules={quillModules}
                formats={quillFormats}
                onChange={handleBody}
                value={body}
                {...formInput("content")}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      layout="vertical"
      className="page-fullform"
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <Title level={4}>Page Content</Title>
          <Tabs type="card" items={items} />
        </Col>
        <Col xs={24} lg={8}>
          <Title level={4}>Page Settings</Title>
          <Form.Item {...formLabel("Menu", "menu_id")} initialValue={0}>
            <Select size="large" style={{ width: "100%" }}>
              <Select.Option key={34} value={0}>
                No parent
              </Select.Option>
              {flattenMenus(response?.data?.menus).map((item, index) => (
                <Select.Option key={index} value={item._id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Form.Item>
            <Checkbox onChange={handleCheckbox} checked={check}>
              Add title link
            </Checkbox>
          </Form.Item>
          {check && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item {...formLabel("Link Title", "link_title")}>
                <Input {...formInput("link_title")} />
              </Form.Item>
              <Form.Item {...formLabel("Link Title (RU)", "link_title_ru")}>
                <Input {...formInput("link_title_ru")} />
              </Form.Item>
              <Form.Item {...formLabel("Link Title (UZ)", "link_title_uz")}>
                <Input {...formInput("link_title_uz")} />
              </Form.Item>
              <Form.Item
                {...formLabel("URL", "link_url")}
                rules={[
                  {
                    required: true,
                    message: "Please input URL!",
                  },
                  {
                    type: "url",
                    warningOnly: true,
                  },
                  {
                    type: "string",
                    min: 6,
                  },
                ]}
              >
                <Input {...formInput("link_url")} />
              </Form.Item>
            </Space>
          )}
          <Divider />
          <Form.Item
            label="Image"
            name="image"
            rules={[
              {
                required: true,
                message: "Please upload Image!",
              },
            ]}
          >
            <ImageUpload name="image" data={data} form={form} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="reset"
                size="large"
                loading={postRequest.loading || putRequest.loading}
              >
                Reset
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={postRequest.loading || putRequest.loading}
              >
                {data !== null ? "Update" : "Create"}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PagesForm;
