import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Space, Table } from "antd";
import EditDrawerBtn from "../../Atoms/Buttons/EditDrawerBtn";
import DeleteModalBtn from "../../Atoms/Buttons/DeleteModalBtn";
import { menuDelete } from "../../../utils/url";
import { AiOutlineHolder } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import clsx from "clsx";

const MenuDnDItem = ({ item, request, close, loading }) => {
  const [open, setOpen] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setOpen(false);
  }, [loading]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Title Ru",
      dataIndex: "title_ru",
      key: "title_ru",
    },
    {
      title: "Title Uz",
      dataIndex: "title_uz",
      key: "title_uz",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn type={"menu"} data={record} request={request} />
          <DeleteModalBtn url={menuDelete} id={record._id} request={request} />
        </Space>
      ),
    },
  ];

  return (
    <div className="menu-dnd__item" style={style}>
      <div className="menu-dnd__item-header">
        <Space>
          <div ref={setNodeRef} {...attributes} {...listeners}>
            <AiOutlineHolder className="icon menu-dnd__holder" />
          </div>
          <p className="menu-dnd__text">{item.title}</p>
        </Space>
        <Space>
          {item?.children && (
            <IoIosArrowDropdown
              className={clsx("icon", open && close && "active")}
              onClick={() => setOpen(!open)}
            />
          )}
          <EditDrawerBtn type="menu" data={item} request={request} />
          <DeleteModalBtn url={menuDelete} id={item._id} request={request} />
        </Space>
      </div>
      <Table
        className={clsx("menu-dnd__subtable", open && close && "active")}
        columns={columns}
        dataSource={item?.children}
        bordered
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default MenuDnDItem;
