import React from "react";
import { Space, Table } from "antd";
import EditDrawerBtn from "../components/Atoms/Buttons/EditDrawerBtn";
import DeleteModalBtn from "../components/Atoms/Buttons/DeleteModalBtn";
import { useLoad } from "../hooks/request";
import { newsDelete, newsList } from "../utils/url";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";
import { AiOutlinePlusCircle } from "react-icons/ai";
import useLanguage from "../hooks/useLanguage";
import { useGetTitle } from "../hooks/useGetTitle";

const NewsPage = () => {
  const { translateTitle } = useGetTitle();
  const getRequest = useLoad({ url: newsList });
  const { response, loading } = getRequest;
  const translate = useLanguage();

  const columns = [
    {
      title: "Title",
      dataIndex: translateTitle("title"),
      key: "title",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn
            type={"news"}
            data={record}
            request={getRequest.request}
          />
          <DeleteModalBtn
            url={newsDelete}
            id={record._id}
            request={getRequest.request}
          />
        </Space>
      ),
    },
  ];
  return (
    <section className="page">
      <header className="page__header">
        <h1 className="page__title">News</h1>
        <PostDrawerBtn type="news" request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-news")}
        </PostDrawerBtn>
      </header>
      <div className="page__content">
        <Table
          columns={columns}
          dataSource={response?.data?.news?.news}
          bordered
          pagination={{
            pageSize: 10,
            total: response?.data?.news?.pagination?.total,
            onChange: (page) => {
              getRequest.request({
                url: `${newsList}?page=${page}&size=10`,
              });
            },
          }}
          loading={loading}
          rowKey="id"
        />
      </div>
    </section>
  );
};

export default NewsPage;
