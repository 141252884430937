import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Routes from '../router';
import jwt_decode from 'jwt-decode';

function App() {
    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
            if (jwt_decode(accessToken).exp < Date.now() / 1000) {
                navigate('/logout');
            }
        }
    }, []);

    return <Routes />;
}

export default App;
