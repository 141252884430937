import React, { useEffect } from 'react';
import { Spin } from 'antd';

const LogoutPage = () => {
    useEffect(() => {
        localStorage.clear();
        window.location.reload(true);
    }, []);

    return (
        <div className="logout">
            <Spin size="large" />
        </div>
    );
};

export default LogoutPage;
