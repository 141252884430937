import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';

const Routes = () => {
    const { user } = useContext(UserContext);

    return user ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;
