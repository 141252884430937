import React from 'react';

export default function ImageWrapper({ children }) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 120,
                height: 70,
            }}>
            {children}
        </div>
    );
}
