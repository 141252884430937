import React, { useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { menuList, menuPost, menuPut } from "../../utils/url";
import { useLoad, usePostRequest, usePutRequest } from "../../hooks/request";

const MenuForm = ({ setOpen, data = null, request }) => {
  const putRequest = usePutRequest({ url: `${menuPut}${data?._id}` });
  const postRequest = usePostRequest({ url: menuPost });
  const getRequest = useLoad({ url: menuList });

  const [form] = Form.useForm();

  const formLabel = (title, name) => ({
    label: title,
    name: name,
    rules: [
      {
        required: true,
        message: `Please input ${title}!`,
      },
    ],
  });

  const formInput = (name) => ({
    placeholder: "Type here",
    name: name,
  });

  const onFinish = async (values) => {
    if (values.parent_id === 0) delete values.parent_id;
    if (data !== null) {
      await putRequest.request({ data: values, sync: true });
    } else {
      await postRequest.request({ data: values, sync: true });
    }
    await request();
    setOpen(false);
    message.success("Success!");
  };

  const onFinishFailed = ({ errorFields }) =>
    message.error(errorFields[0].errors[0]);

  useEffect(() => {
    if (data !== null) form.setFieldsValue(data);
  }, []);

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      className="fixed-form"
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Form.Item {...formLabel("Title", "title")}>
            <Input {...formInput("title")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Title ru", "title_ru")}>
            <Input {...formInput("title_ru")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Title uz", "title_uz")}>
            <Input {...formInput("title_uz")} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...formLabel("Parent id", "parent_id")} initialValue={0}>
            <Select size="middle" width="100%">
              <Select.Option key={34} value={0}>
                No parent
              </Select.Option>
              {getRequest?.response?.data?.menus?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item._id}>
                    {item.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24} style={{ marginTop: 10 }}>
          <Button
            htmlType="reset"
            type="default"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            Reset
          </Button>
        </Col>
        <Col span={24}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={postRequest.loading || putRequest.loading}
          >
            {data !== null ? "Edit" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default MenuForm;
