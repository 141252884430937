import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Image, Row, Space, Table } from "antd";
import useLanguage from "../hooks/useLanguage";
import { useLoad } from "../hooks/request";
import { videoDelete, videoList } from "../utils/url";
import EditDrawerBtn from "../components/Atoms/Buttons/EditDrawerBtn";
import DeleteModalBtn from "../components/Atoms/Buttons/DeleteModalBtn";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";
import { useGetTitle } from "../hooks/useGetTitle";

export default function VideosPage() {
  const { translateTitle } = useGetTitle();
  const getRequest = useLoad({ url: videoList });
  const { response, loading } = getRequest;
  const translate = useLanguage();

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      render: (record) => (
        <Row align="middle" justify="center" style={{ width: 120, height: 50 }}>
          <Image
            width="100%"
            height="100%"
            src={record}
            alt="image"
            style={{ objectFit: "cover" }}
          />
        </Row>
      ),
    },
    {
      title: "Title",
      dataIndex: translateTitle("title"),
      key: "id",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn
            type={"videos"}
            data={record}
            request={getRequest.request}
          />
          <DeleteModalBtn
            url={videoDelete}
            id={record._id}
            request={getRequest.request}
          />
        </Space>
      ),
    },
  ];

  return (
    <section className="page">
      <header className="page__header">
        <h1 className="page__title">Videos</h1>
        <PostDrawerBtn type={"videos"} request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-videos")}
        </PostDrawerBtn>
      </header>
      <div className="page__content">
        <Table
          columns={columns}
          dataSource={response?.data?.videos}
          bordered
          loading={loading}
          rowKey="id"
        />
      </div>
    </section>
  );
}
