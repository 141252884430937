import * as React from 'react';

const LogoSvg = (props) => (
    <svg
        width={137}
        height={60}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.677 15.201h1v2.757h.689v-2.757h.992v-.6h-2.681v.6Zm4.152-.6h-1.026v3.357h.652v-2.706l.689 2.706h.71l.681-2.706v2.706h.651v-3.357h-1.029l-.66 2.556-.668-2.556Z"
            fill="#000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.788 39.751c-3.66 0-5.572-1.741-5.656-5.148h-7.359c.459 7.225 4.493 10.754 12.267 10.754 8.401 0 13.015-3.613 13.015-9.424 0-2.572-.996-4.733-2.95-6.31-1.41-1.128-3.802-1.904-7.094-2.973l-.306-.099c-.534-.175-1.038-.336-1.511-.487-3.687-1.18-5.516-1.765-5.516-3.75 0-1.494 1.576-2.699 4.156-2.699 3.077 0 4.695 1.289 5.031 4.07h7.19c0-5.937-4.45-9.839-11.725-9.839-7.484 0-11.93 3.739-11.935 9.34 0 3.697 2.245 6.558 6.569 8.262 2.276.91 4.292 1.502 5.955 1.99 1.527.45 2.755.81 3.61 1.249.706.373 1.164 1.12 1.164 2.114 0 1.83-1.702 2.95-4.905 2.95ZM22.361 14.677H14.71v29.849h7.652v-29.85Zm3.152 0h23.118v6.1H33.166v5.48h15.423v5.98H33.166v6.188h15.549v6.1H25.513V14.678Zm26.072 0h7.69v23.37h14.058v6.479H51.585v-29.85Zm16.053 6.059h8.565v23.79h7.649v-23.79h8.61v-6.06H67.638v6.06Z"
            fill="#E31837"
        />
    </svg>
);

export default LogoSvg;
