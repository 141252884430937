import React, { useState } from "react";
import { menuChangePosition } from "../../../utils/url";
import { usePostRequest } from "../../../hooks/request";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import MenuDnDItem from "./MenuDndItem";
import EmptyData from "../../Atoms/Empty/EmptyData";
import LoadingSpin from "../../Atoms/LoadingSpin";

const MenuDnD = ({ response, request, loading }) => {
  const [close, setClose] = useState(true);
  const postRequest = usePostRequest({ url: menuChangePosition });

  async function handleDragEnd(event) {
    const { active, over } = event;
    if (active._id !== over._id) {
      const overObj = response.data.menus.filter((x) => x._id === over._id);
      const activeObj = response.data.menus.filter((x) => x._id === active._id);
      await postRequest.request({
        data: {
          id: active._id,
          position: overObj[0].position,
          parent_id: activeObj[0].parent_id,
        },
      });
      setClose(false);
      request();
    }
  }

  if (response === undefined)
    return <LoadingSpin loading={postRequest.loading || loading} />;

  return (
    <LoadingSpin loading={postRequest.loading || loading}>
      <div className="menu-dnd__context">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={response?.data?.menus}
            strategy={verticalListSortingStrategy}
          >
            {response?.data?.menus?.map((item) => (
              <MenuDnDItem
                key={item._id}
                item={item}
                request={request}
                loading={loading || postRequest.loading}
                close={close}
              />
            ))}
            {response?.data?.menus?.length === 0 && <EmptyData />}
          </SortableContext>
        </DndContext>
      </div>
    </LoadingSpin>
  );
};

export default MenuDnD;
