import React from 'react';
import { Spin } from 'antd';

const LoadingSpin = ({ children, loading }) => {
    return (
        <>
            {loading && (
                <div className="loading-spin">
                    <Spin size="large" style={{ zIndex: 10 }} />
                </div>
            )}
            {children}
        </>
    );
};

export default LoadingSpin;
