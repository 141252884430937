import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Space, Table } from "antd";
import useLanguage from "../hooks/useLanguage";
import { useLoad } from "../hooks/request";
import { footerDelete, footerList } from "../utils/url";
// ? Buttons
import EditDrawerBtn from "../components/Atoms/Buttons/EditDrawerBtn";
import DeleteModalBtn from "../components/Atoms/Buttons/DeleteModalBtn";
import PostDrawerBtn from "../components/Atoms/Buttons/PostDrawerBtn";

const FooterPage = () => {
  const translate = useLanguage();
  const getRequest = useLoad({ url: footerList });
  const { loading, response } = getRequest;

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Title ru",
      dataIndex: "title_ru",
      key: "title_ru",
    },
    {
      title: "Title uz",
      dataIndex: "title_uz",
      key: "title_uz",
    },
    {
      title: "",
      key: "",
      render: (data, record) => (
        <Space className="crud__actions">
          <EditDrawerBtn
            type={"footer"}
            data={record}
            request={getRequest.request}
          />
          <DeleteModalBtn
            url={footerDelete}
            id={record._id}
            request={getRequest.request}
          />
        </Space>
      ),
    },
  ];

  return (
    <section className="page footer-page">
      <header className="page__header">
        <h1 className="page__title">Footer</h1>
        <PostDrawerBtn type={"footer"} request={getRequest.request}>
          <AiOutlinePlusCircle className="icon" />
          {translate("add-link")}
        </PostDrawerBtn>
      </header>
      <div className="page__content">
        <Table
          columns={columns}
          dataSource={response?.data?.menus?.map((item) => item)}
          pagination={false}
          rowKey="id"
          loading={loading}
          bordered
        />
      </div>
    </section>
  );
};

export default FooterPage;
