import { message, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { $mediaApi, $authHost } from "../../utils/https";
import { getDeleteKey, handleEncrypted } from "../../utils/utils";
import FileResizer from "react-image-file-resizer";

export default function ImageUpload({ name = "image", form, data = null }) {
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const ErrorImage = () => {
    return [
      {
        uid: "22",
        name: "image.png",
        status: "error",
      },
    ];
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = (file) => {
    setPreviewImage(file.url);
    setPreviewOpen(true);
    setPreviewTitle(file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const onRemove = async (file = {}) => {
    const { url, status } = file;
    if (status === "error") {
      setFileList([]);
    }

    const deleteKey = getDeleteKey(url);
    console.log("delte key: ", deleteKey);

    try {
      const res = await $authHost.post("/remove-image", {
        key: deleteKey,
      });
      setLoading(false);

      const data = res.data;
      if (!!data) {
        setFileList([]);
        form.setFieldsValue({ [name]: "" });
      }
    } catch (error) {
      setFileList([...ErrorImage()]);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async ({ file }) => {
    setLoading(true);
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/webp";

    if (!isJpgOrPng) {
      setLoading(false);

      setFileList([...ErrorImage()]);

      return message.error("You can only upload JPG/PNG file!");
    } else {
      FileResizer.imageFileResizer(
        file,
        720,
        500,
        file.type,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await $authHost.post(
              "/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            // setImage(data);

            form.setFieldsValue({ [name]: data.Location });

            setFileList([
              {
                uid: data.Key,
                status: "done",
                url: data.Location,
              },
            ]);

            form.setFieldValue("image", data.Location);
          } catch (error) {
            setFileList([...ErrorImage()]);
          } finally {
            setLoading(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue(data);
      setFileList([
        {
          uid: data[name],
          status: "done",
          url: data[name],
        },
      ]);
    }
  }, [data]);

  const uploadButton = (
    <div>
      {loading ? (
        <BiLoader className="icon" />
      ) : (
        <AiOutlineUpload className="icon" />
      )}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        customRequest={(file) => handleChange(file)}
        onPreview={handlePreview}
        onRemove={onRemove}
      >
        {fileList?.length === 1 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
