import React, { useContext } from 'react';
import { Select, Space } from 'antd';
import { LanguageContext } from '../../context/LanguageContext';

const LangSelect = () => {
    const { setLanguage } = useContext(LanguageContext);
    const language = localStorage.getItem('lang') || 'en';

    const onChange = (value) => {
        localStorage.setItem('lang', value);
        setLanguage(value);
    };

    return (
        <Space style={{ marginRight: 12 }}>
            <Select
                defaultValue={language}
                onChange={onChange}
                options={[
                    {
                        value: 'en',
                        label: 'EN',
                    },
                    {
                        value: 'ru',
                        label: 'RU',
                    },
                    {
                        value: 'uz',
                        label: 'UZ',
                    },
                ]}
            />
        </Space>
    );
};

export default LangSelect;
